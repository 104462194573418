<template>
    <div>
        <a href="https://www.salesforceapi.dev/dreamforce-cicd">Click here to access my "Salesforce CI/CD Design Patterns" dreamforce session recap!</a>
    </div>
  
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

    div{
        margin: 150px auto;
        text-align: center;
        font-size: 30px;
    }
</style>>
    